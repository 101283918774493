import React from "react"
import { Link } from "gatsby"

import { Section } from 'react-basic-components';

import gallery from '../images/editorial';

export default class IndexPage extends React.Component{
  render(){

    const tags = ['#school', '#grim', '#editorial', '#halloween', '#probeerseltje']
    let photos = gallery.reduce((array, item) => {
      array.push({
        image: item,
        tags: tags.reduce(
            (result, tag) => {
              if(Math.random() > 0.5) result.push(tag);
              return result;
            },
            []
          ),
      })
      return array;
    }, []);

    return (
      <>
        <Section id='starter' className='full-height white' bgImage={gallery[0]}>
          <h1>Sanne van Almkerk</h1>
          <h2>MUA | kapsels | make-up</h2>
          <button className='next' onClick={() => document.getElementById('portfolio').scrollIntoView()}>▾ Bekijk mijn werk ▾</button>
        </Section>
        <Section id='portfolio' fullWidth={true}>
          <div className='menu'>
            <p>Bekijk:</p>
            <button className='active'>Alles</button>
            {tags.map((item) => {
              return <button>{item}</button>
            })}
          </div>
          <div className='gallery'>
            {photos.map((item, i) => {
              return (
                <div key={i}>
                  <div className='image' style={{backgroundImage: `url('${item.image}')`}} />
                  <div className='tags'>
                    {item.tags.map((tag, i) => {
                      if(i < 3) return <p key={tag}>{tag}</p>
                      return null;
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </Section>
      </>
    )
  }
}
